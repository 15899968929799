export interface IToken {
    sub: number;
    is_superuser: boolean;
    is_city_admin: boolean;
    city_id?: number;
    appearances_write_access: number[];
}

export interface IUserProfile {
    email: string;
    is_active: boolean;
    is_superuser: boolean;
    is_city_admin?: boolean;
    city_id?: number;
    full_name: string;
    appearances_write_access: number[];
    id: number;
}

export interface IUserProfileUpdate {
    email?: string;
    full_name?: string;
    password?: string;
    is_active?: boolean;
    is_superuser?: boolean;
    is_city_admin?: boolean;
    city_id?: number;
    appearances_write_access?: number[];
}

export interface IUserProfileCreate {
    email: string;
    full_name?: string;
    is_active?: boolean;
    is_superuser?: boolean;
    is_city_admin?: boolean;
    city_id?: number;
    appearances_write_access?: number[];
}

export interface ICity {
    id: number;
    name: string;
    is_active: boolean;
    latitude: number;
    longitude: number;
    radius: number;
    image_filename?: string;
    one_signal_app_id?: string;
    one_signal_api_key?: string;
    one_signal_api_key_expiration_date?: Date;
}

export interface ICityUpdate {
    name?: string;
    is_active?: boolean;
    latitude?: number;
    longitude?: number;
    radius?: number;
    coat_of_arms?: File;
    one_signal_app_id?: string;
    one_signal_api_key?: string;
    one_signal_api_key_expiration_date?: Date;
}

export interface ICityCreate {
    name: string;
    is_active: boolean;
    latitude: number;
    longitude: number;
    radius: number;
    coat_of_arms?: File;
    one_signal_app_id?: string;
    one_signal_api_key?: string;
    one_signal_api_key_expiration_date?: Date;
}

export interface ITicketSubType {
    id?: number;
    title: string;
}

export interface ITicketType extends ITicketSubType {
    sub_types: ITicketSubType[];
}

export interface ITicketGet {
    total: number;
    tickets: ITicket[];
}

export interface ITicket {
    id: number;
    title: string;
    description?: string;
    city_feedback?: string;
    image_filename?: string;
    latitude: number;
    longitude: number;
    location_text?: string;
    reporter_name?: string;
    reporter_email?: string;
    created_at: Date;
    ticket_sub_type: { id: number, title: string, owner_type: { id: number, title: string } };
    is_active: boolean;
}

export interface ITicketUpdate {
    title?: string;
    description?: string;
    city_feedback?: string;
    file?: File;
    latitude?: number;
    longitude?: number;
    location_text?: string;
    reporter_name?: string;
    reporter_email?: string;
    ticket_sub_type_id?: number;
    is_active?: boolean;
}

export interface ITicketCreate {
    title: string;
    description: string;
    latitude: number;
    longitude: number;
    location_text?: string;
    reporter_name?: string;
    reporter_email?: string;
    ticket_sub_type_id: number;
    file?: File;
}

export enum HomePageCellType {
    LINK_CELL = "LINK_CELL",
    INTERNAL_LINK_CELL = "INTERNAL_LINK_CELL",
}

export enum HomePageRowType {
    SINGLE_CELL = "SINGLE_CELL",
    SPLIT_CELL = "SPLIT_CELL",
    MULTI_CELL = "MULTI_CELL",
}

export interface IHomePageCellGet {
    id: number;
    order_id: number;
    type: HomePageCellType;

    title?: string;
    link?: string;
    internal_link?: string;
    image_filename?: string;
}

export interface IHomePageRowGet {
    id: number;
    cells: IHomePageCellGet[];
    order_id: number;
    type: HomePageRowType;
}

export interface IHomePageCellCreate {
    id?: number;
    order_id: number;
    type: HomePageCellType;

    title?: string;
    link?: string;
    internal_link?: string;
    image_filename?: string;
}

export interface IHomePageRowCreate {
    id?: number;
    order_id: number;
    type: HomePageRowType;
    cells: IHomePageCellCreate[];
}

export interface IHomePageRowsCreate {
    rows: IHomePageRowCreate[];
}

export interface IAppearanceTreeSubNodeGet {
    id: number;
    title: string;
    order_id: number;
}

export interface IAppearanceTreeNodeGet extends IAppearanceTreeSubNodeGet {
    sub_nodes: IAppearanceTreeSubNodeGet[];
}

export interface IAppearanceTreeAppearanceOrderNode {
    id: number;
    order_id: number;
}

interface IAppearanceTreeNodeBase {
    id?: number;
    order_id: number;
    title: string;
}

export interface IAppearanceTreeSubNodeCreate extends IAppearanceTreeNodeBase {
    appearance_order_nodes: IAppearanceTreeAppearanceOrderNode[];
}

export interface IAppearanceTreeNodeCreate extends IAppearanceTreeNodeBase {
    sub_nodes: IAppearanceTreeSubNodeCreate[];
}

export interface IAppearanceTreeSubNodeGet extends IAppearanceTreeNodeBase {
    id: number;
}

export interface IAppearanceTreeNodeGet extends IAppearanceTreeNodeBase {
    id: number;
    sub_nodes: IAppearanceTreeSubNodeGet[];
}

interface IAppearanceBase {
    order_id: number;
    title: string;
    content: string;
    files: string[];
    appearance_tree_node_id: number;
}

export interface IAppearanceGet extends IAppearanceBase {
    id: number;
}

export interface IAppearanceCreate extends IAppearanceBase { }


export interface ICitySettings {
    city_id: number;
    ticket_image_required: boolean;
    ticket_location_required: boolean;
    ticket_user_data_required: boolean;
    ticket_inactive_at_creation: boolean;
}


export interface ICityLegalTexts {
    site_notice: string;
    privacy_declaration: string;
    barrier_declaration: string;
}
