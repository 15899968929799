



























































































































import { apiUrl } from "@/env";
import {
  HomePageRowType,
  IHomePageCellCreate,
  IHomePageRowCreate,
} from "@/interfaces";
import { Vue, Component } from "vue-property-decorator";

const HomePagePreviewProps = Vue.extend({
  props: {
    homePageRows: { type: Array },
    files: { type: Array },
  },
});

@Component({
  components: {
    SheetFooter: {
      functional: true,
      render(h, { children }) {
        return h(
          "v-sheet",
          {
            staticClass: "mt-auto align-center justify-center d-flex px-2",
            props: {
              color: "rgba(0, 0, 0, .36)",
              dark: true,
              height: "15%",
            },
          },
          children
        );
      },
    },
  },
})
export default class HomePagePreview extends HomePagePreviewProps {
  public async mounted() {}

  public isSingleCell(homePageRow: IHomePageRowCreate) {
    return homePageRow.type == HomePageRowType.SINGLE_CELL;
  }
  public isSplitCell(homePageRow: IHomePageRowCreate) {
    return homePageRow.type == HomePageRowType.SPLIT_CELL;
  }
  public isMultiCell(homePageRow: IHomePageRowCreate) {
    return homePageRow.type == HomePageRowType.MULTI_CELL;
  }

  public getImage(homePageCell: IHomePageCellCreate) {
    const filtered = this.files
      .filter((value: any) => value.name == homePageCell.image_filename)
      .map((value: any) => URL.createObjectURL(value.file));

    if (filtered.length >= 1) return filtered[0];

    return apiUrl + "/api/v1" + homePageCell.image_filename;
  }
}
