









































































































































































import HomePagePreview from "@/components/HomePagePreview.vue";
import {
  HomePageCellType,
  HomePageRowType,
  IHomePageCellCreate,
  IHomePageRowCreate,
  IHomePageRowGet,
} from "@/interfaces";
import {
  dispatchGetHomePage,
  dispatchPostHomePage,
} from "@/store/admin/actions";
import { readCityId } from "@/store/main/getters";
import { isValidHttpUrl } from "@/utils";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    HomePagePreview,
  },
})
export default class CityHomePage extends Vue {
  private homePageRows: IHomePageRowCreate[] = [];
  private files: { name: string; file: File }[] = [];
  private loaded = false;
  private rules = {
    required: (value?: string) => !!value || "Pflichtfeld",
    isInteger: (value: string) =>
      !isNaN(parseInt(value)) || "Valide URL eingeben",
  };

  private rowTypes = [
    { id: HomePageRowType.SINGLE_CELL, title: "Einzelzelle" },
    { id: HomePageRowType.SPLIT_CELL, title: "Doppelzelle" },
    { id: HomePageRowType.MULTI_CELL, title: "Mehrfachzelle" },
  ];
  private cellTypes = [
    { id: "link", title: "Link" },
    { id: "/ticket/new", title: "Neue Mängelmeldung" },
    { id: "/ticket/overview", title: "Mängelmelder" },
    { id: "/appearance/", title: "Auftritt" },
    { id: "/appearanceCategory/", title: "Auftritt-Kategorie" },
  ];

  public async mounted() {
    await this.loadHomePage();
  }

  private isValidInternalLink(homePageCell: IHomePageCellCreate) {
    if (
      homePageCell.internal_link == "/ticket/new" ||
      homePageCell.internal_link == "/ticket/overview"
    ) {
      return true;
    }

    if (
      homePageCell.internal_link?.startsWith("/appearance/") &&
      !isNaN(parseInt(this.getAppearanceId(homePageCell) ?? ""))
    ) {
      return true;
    }

    if (
      homePageCell.internal_link?.startsWith("/appearanceCategory/") &&
      !isNaN(parseInt(this.getAppearanceCategoryId(homePageCell) ?? ""))
    ) {
      return true;
    }

    return false;
  }

  private getCellTypeValue(homePageCell: IHomePageCellCreate) {
    if (homePageCell.type == HomePageCellType.LINK_CELL) return "link";

    if (homePageCell.internal_link?.startsWith("/appearance/"))
      return "/appearance/";

    if (homePageCell.internal_link?.startsWith("/appearanceCategory/"))
      return "/appearanceCategory/";

    return homePageCell.internal_link;
  }

  private cellTypeValueChanged(
    newValue: string,
    homePageCell: IHomePageCellCreate
  ) {
    homePageCell.internal_link = undefined;
    homePageCell.link = undefined;

    if (newValue == "link") {
      homePageCell.type = HomePageCellType.LINK_CELL;
      homePageCell.link = "";
    } else {
      homePageCell.type = HomePageCellType.INTERNAL_LINK_CELL;
      homePageCell.internal_link = newValue;
    }
  }

  private getAppearanceId(homePageCell: IHomePageCellCreate) {
    return homePageCell.internal_link?.split("/")[2];
  }

  private getAppearanceCategoryId(homePageCell: IHomePageCellCreate) {
    return homePageCell.internal_link?.split("/")[2];
  }

  private appearanceIdChanged(
    newValue: string,
    homePageCell: IHomePageCellCreate
  ) {
    homePageCell.internal_link = "/appearance/" + newValue;
  }

  private appearanceCategoryIdChanged(
    newValue: string,
    homePageCell: IHomePageCellCreate
  ) {
    homePageCell.internal_link = "/appearanceCategory/" + newValue;
  }

  private changedType(row: IHomePageRowCreate) {
    if (row.type == HomePageRowType.SINGLE_CELL) {
      if (row.cells.length == 0) this.addCell(row);
      else row.cells = [row.cells[0]];
    }
    if (row.type == HomePageRowType.SPLIT_CELL) {
      while (row.cells.length < 2) this.addCell(row);

      if (row.cells.length > 2) row.cells = [row.cells[0], row.cells[1]];
    }
  }

  private addRow() {
    const newRow: IHomePageRowCreate = {
      order_id: this.homePageRows.length,
      type: HomePageRowType.SINGLE_CELL,
      cells: [],
    };
    this.addCell(newRow);
    this.homePageRows.push(newRow);
  }

  private addCell(homePageRow: IHomePageRowCreate) {
    const newCell: IHomePageCellCreate = {
      order_id: this.homePageRows.length,
      type: HomePageCellType.LINK_CELL,
    };
    homePageRow.cells.push(newCell);
  }

  public selectedImage(
    homePageRow: IHomePageRowCreate,
    homePageCell: IHomePageCellCreate,
    file: File
  ) {
    const fileName = `${homePageRow.order_id}-${homePageCell.order_id}`;
    var i = -1;
    for (let index = 0; index < this.files.length; index++) {
      if (fileName == this.files[index].name) {
        i = index;
        break;
      }
    }
    if (i != -1) {
      this.$delete(this.files, i);
      homePageCell.image_filename = undefined;
    }
    if (file != null) {
      this.files.push({ name: fileName, file: file });
      homePageCell.image_filename = fileName;
    }
  }

  private isLinkCell(homePageCell: IHomePageCellCreate) {
    return homePageCell.type == HomePageCellType.LINK_CELL;
  }

  private isAppearanceCell(homePageCell: IHomePageCellCreate) {
    return (
      homePageCell.type == HomePageCellType.INTERNAL_LINK_CELL &&
      homePageCell.internal_link?.startsWith("/appearance/")
    );
  }

  private isAppearanceCategoryCell(homePageCell: IHomePageCellCreate) {
    return (
      homePageCell.type == HomePageCellType.INTERNAL_LINK_CELL &&
      homePageCell.internal_link?.startsWith("/appearanceCategory/")
    );
  }

  private isMultiRow(homePageRow: IHomePageRowCreate) {
    return homePageRow.type == HomePageRowType.MULTI_CELL;
  }

  private deleteRow(homePageRow: IHomePageRowGet) {
    this.$delete(this.homePageRows, this.homePageRows.indexOf(homePageRow));
  }

  private deleteCell(homePageRow: IHomePageRowGet, cellIdx: number) {
    this.$delete(homePageRow.cells, cellIdx);
  }

  private async save() {
    if (!this.canSave()) return;

    await dispatchPostHomePage(this.$store, {
      cityId: readCityId(this.$store),
      homePage: { rows: this.homePageRows },
      files: this.files,
    });

    this.loaded = false;
    await this.loadHomePage();
    this.files = [];
  }

  private async loadHomePage() {
    this.homePageRows = await dispatchGetHomePage(
      this.$store,
      readCityId(this.$store)
    );
    this.sortRows();
    this.loaded = true;
  }

  private sortRows() {
    this.homePageRows.sort((a, b) => a.order_id - b.order_id);
    this.homePageRows.forEach((row) =>
      row.cells.sort((a, b) => a.order_id - b.order_id)
    );
  }

  private isTitleOrImageValid(homePageCell: IHomePageCellCreate) {
    return (
      (homePageCell.title ?? "") != "" ||
      (homePageCell.image_filename ?? "") != ""
    );
  }

  private getTitleOrImageError(homePageCell: IHomePageCellCreate) {
    if (!this.isTitleOrImageValid(homePageCell))
      return "Es muss ein Bild oder ein Titel angegeben werden.";
    return "";
  }

  private isValidLink(homePageCell: IHomePageCellCreate) {
    return isValidHttpUrl(homePageCell.link ?? "");
  }

  private getLinkError(homePageCell: IHomePageCellCreate) {
    if (!this.isValidLink(homePageCell)) {
      return "Bitte geben Sie eine valide URL ein.";
    }
    return "";
  }

  private canSave() {
    for (const homePageRow of this.homePageRows) {
      for (const homePageCell of homePageRow.cells) {
        const invalidContent =
          !this.isValidLink(homePageCell) &&
          !this.isValidInternalLink(homePageCell);
        if (!this.isTitleOrImageValid(homePageCell) || invalidContent) {
          return false;
        }
      }
    }

    return true;
  }
}
